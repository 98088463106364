// Admin endpoints used for review and managment
import girderRest from 'platform/web-girder/plugins/girder';
function getRecentDatasets(limit, offset) {
    return girderRest.get(`dive_dataset?limit=${limit}&sort=created&sortdir=-1&offset=${offset}&published=false&shared=false`);
}
function getJobTypesStatus() {
    return girderRest.get('job/typeandstatus/all');
}
function getRecentJobs(limit, offset, statuses, types) {
    return girderRest.get('job/all', {
        params: {
            limit,
            offset,
            statuses: JSON.stringify(statuses),
            sort: 'created',
            sortdir: -1,
            types: JSON.stringify(types),
        },
    });
}
function getRecentUsers(limit, offset) {
    return girderRest.get(`user?limit=${limit}&sort=created&sortdir=-1&offset=${offset}`);
}
function cancelJob(jobId) {
    return girderRest.put(`job/${jobId}/cancel`);
}
function deleteJob(jobId) {
    return girderRest.delete(`job/${jobId}`);
}
export { getRecentDatasets, getRecentJobs, getRecentUsers, getJobTypesStatus, cancelJob, deleteJob, };
