import BaseAnnotationStore from './BaseAnnotationStore';
import Group from './Group';
import MultiMap from './MultiMap';
export default class GroupStore extends BaseAnnotationStore {
    constructor({ markChangesPending, cameraName }) {
        super({ markChangesPending, cameraName });
        this.trackMap = new MultiMap();
        this.defaultGroup = ['no-group', 1.0];
    }
    insert(group, args) {
        super.insert(group, args);
        group.memberIds.forEach((id) => {
            this.trackMap.add(id, group.id);
        });
        group.setNotifier((params) => {
            super.notify(params);
            if (params.event === 'remove-members') {
                params.oldValue.forEach((trackId) => {
                    this.trackMap.remove(trackId, group.id);
                });
            }
            else if (params.event === 'members') {
                group.memberIds.forEach((id) => {
                    this.trackMap.add(id, group.id);
                });
            }
        });
    }
    /**
     * Initialize a new group with members
     */
    add(members, defaultType) {
        const newId = this.getNewId();
        const begin = Math.min(...members.map((m) => m.begin));
        const end = Math.max(...members.map((m) => m.end));
        const memberMap = {};
        members.forEach((m) => {
            memberMap[m.id] = {
                ranges: [[m.begin, m.end]],
            };
        });
        const group = new Group(newId, {
            begin,
            end,
            confidencePairs: [[defaultType, 1]],
            members: memberMap,
        });
        this.insert(group);
        return group;
    }
    remove(annotationId, disableNotifications = false) {
        const group = this.get(annotationId);
        group.memberIds.forEach((id) => {
            this.trackMap.remove(id, group.id);
        });
        super.remove(annotationId, disableNotifications);
    }
    /**
     * Notify the group store that a track has been removed
     */
    trackRemove(annotationId) {
        this.lookupGroups(annotationId).forEach((group) => {
            /** Remove deleted track from group reference */
            group.removeMembers([annotationId]);
            if (group.memberIds.length === 0) {
                /** If you removed the last track, delete the group */
                this.remove(group.id);
            }
        });
    }
    lookupGroups(trackId) {
        const groupIds = this.trackMap.get(trackId);
        if (groupIds) {
            return Array.from(groupIds).map((v) => this.get(v));
        }
        return [];
    }
    clearAll() {
        this.trackMap = new MultiMap();
        super.clearAll();
    }
}
