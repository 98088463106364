/* eslint-disable max-len */
import { isArray } from 'lodash';
export const checkAttributes = (attributeMatch, attributeVal, userDefinedVal) => {
    const results = [];
    const checkVal = attributeMatch.userDefined ? userDefinedVal : attributeMatch.val;
    if (attributeVal !== undefined) {
        if (attributeMatch.op) {
            switch (attributeMatch.op) {
                case '=': {
                    // eslint-disable-next-line eqeqeq
                    results.push(attributeVal == checkVal);
                    break;
                }
                case '≠': {
                    // eslint-disable-next-line eqeqeq
                    results.push(attributeVal != checkVal);
                    break;
                }
                case '>': {
                    if (['number', 'string'].includes(typeof checkVal) && checkVal !== null && checkVal !== undefined) {
                        results.push(attributeVal > checkVal);
                    }
                    break;
                }
                case '<': {
                    if (['number', 'string'].includes(typeof checkVal) && checkVal !== null && checkVal !== undefined) {
                        results.push(attributeVal < checkVal);
                    }
                    break;
                }
                case '<=': {
                    if (['number', 'string'].includes(typeof checkVal) && checkVal !== null && checkVal !== undefined) {
                        results.push(attributeVal <= checkVal);
                    }
                    break;
                }
                case '>=': {
                    if (['number', 'string'].includes(typeof checkVal) && checkVal !== null && checkVal !== undefined) {
                        results.push(attributeVal >= checkVal);
                    }
                    break;
                }
                case 'rangeFilter': {
                    if (['number'].includes(typeof checkVal) && checkVal !== null && checkVal !== undefined) {
                        results.push(attributeVal > checkVal);
                    }
                    break;
                }
                case 'range': {
                    if (isArray(checkVal) && checkVal !== null && checkVal !== undefined) {
                        if (checkVal[0] !== null && checkVal[1] !== null) {
                            results.push(attributeVal >= checkVal[0] && attributeVal <= checkVal[1]);
                        }
                    }
                    break;
                }
                case 'contains': {
                    if (checkVal !== null && attributeVal !== null && checkVal !== undefined) {
                        results.push(attributeVal === null || attributeVal === void 0 ? void 0 : attributeVal.toString().includes(checkVal.toString()));
                    }
                    break;
                }
                case 'in': {
                    if (isArray(checkVal) && checkVal !== null && checkVal !== undefined && attributeVal !== null) {
                        if (typeof checkVal[0] === 'number') {
                            results.push(checkVal.includes(attributeVal));
                        }
                        if (typeof checkVal[0] === 'string') {
                            results.push(checkVal.includes(attributeVal));
                        }
                    }
                    break;
                }
                default: {
                    results.push(attributeVal !== undefined);
                }
            }
        }
    }
    else {
        results.push(false);
    }
    return results.filter((item) => item).length === results.length;
};
export const trackIdPassesFilter = (id, getTrack, filters, userDefinedvals, enabled) => {
    const track = getTrack(id);
    const trackAttributes = track.attributes;
    const trackFilters = [];
    const detectionFilters = [];
    const trackUserVals = [];
    const detectionUserVals = [];
    filters.forEach((item, index) => {
        if (enabled[index]) {
            if (item.type === 'track') {
                trackFilters.push(item);
                trackUserVals.push(userDefinedvals[index]);
            }
            else if (item.type === 'detection') {
                detectionFilters.push(item);
                detectionUserVals.push(userDefinedvals[index]);
            }
        }
    });
    for (let i = 0; i < trackFilters.length; i += 1) {
        const filter = trackFilters[i];
        // If we have a type filter only filter by the types specified
        if (filter.typeFilter.length > 0 && !filter.typeFilter.includes(track.getType()[0])) {
            return true;
        }
        if (trackAttributes[filter.attribute] === undefined && !filter.ignoreUndefined) {
            return false;
        }
        const result = checkAttributes(filter.filter, trackAttributes[filter.attribute], trackUserVals[i]);
        if (!result) {
            return false;
        }
    }
    for (let i = 0; i < detectionFilters.length; i += 1) {
        for (let k = 0; k < track.featureIndex.length; k += 1) {
            const index = track.featureIndex[k];
            const detectionAttributes = track.features[index].attributes;
            const filter = detectionFilters[i];
            if (detectionAttributes) {
                if (detectionAttributes[filter.attribute] === undefined && !filter.ignoreUndefined) {
                    return false;
                }
                const result = checkAttributes(filter.filter, detectionAttributes[filter.attribute], detectionUserVals[i]);
                if (!result) {
                    return false;
                }
            }
        }
    }
    return true;
};
