import girderRest from 'platform/web-girder/plugins/girder';
export * from './annotation.service';
export * from './configuration.service';
export * from './dataset.service';
export * from './girder.service';
export * from './rpc.service';
export * from './largeImage.service';
export function unwrap(func) {
    return async (...args) => (await func(...args)).data;
}
export function getUri(config) {
    return girderRest.apiRoot.replace(/\/*$/i, '/') + girderRest.getUri(config).replace(/^\/*/, '');
}
