import { all } from '@girder/components/src/components/Job/status';
import Vue from 'vue';
import eventBus from 'platform/web-girder/eventBus';
import girderRest from 'platform/web-girder/plugins/girder';
const JobStatus = all();
const NonRunningStates = [
    JobStatus.CANCELED.value,
    JobStatus.ERROR.value,
    JobStatus.SUCCESS.value,
];
const jobModule = {
    namespaced: true,
    state: {
        jobIds: {},
        datasetStatus: {},
        completeJobsInfo: {},
    },
    getters: {
        runningJobIds(state) {
            return Object.values(state.jobIds).filter((v) => !NonRunningStates.includes(v)).length >= 1;
        },
        datasetRunningState: (state) => (datasetId) => {
            if (datasetId in state.datasetStatus
                && !NonRunningStates.includes(state.datasetStatus[datasetId].status)) {
                return `/girder/#job/${state.datasetStatus[datasetId].jobId}`;
            }
            return false;
        },
        datasetCompleteJobs: (state) => (datasetId) => {
            if (datasetId in state.completeJobsInfo) {
                return (state.completeJobsInfo[datasetId]);
            }
            return false;
        },
    },
    mutations: {
        setJobState(state, { jobId, value }) {
            Vue.set(state.jobIds, jobId, value);
        },
        setDatasetStatus(state, { datasetId, status, jobId }) {
            Vue.set(state.datasetStatus, datasetId, { status, jobId });
        },
        setCompleteJobsInfo(state, { datasetId, type, title, success, }) {
            Vue.set(state.completeJobsInfo, datasetId, { type, title, success });
        },
        removeCompleteJobsInfo(state, { datasetId }) {
            if (datasetId in state.completeJobsInfo) {
                Vue.delete(state.completeJobsInfo, datasetId);
            }
        },
    },
    actions: {
        removeCompleteJob({ commit }, { datasetId }) {
            commit('removeCompleteJobsInfo', { datasetId });
        },
    },
};
export async function init(store) {
    const { data: runningJobs } = await girderRest.get('/job', {
        params: { statuses: `[${JobStatus.RUNNING.value}, ${JobStatus.QUEUED.value}, ${JobStatus.INACTIVE.value}]` },
    });
    function updateJob(job) {
        store.commit('Jobs/setJobState', { jobId: job._id, value: job.status });
        if (typeof job.dataset_id === 'string') {
            store.commit('Jobs/setDatasetStatus', { datasetId: job.dataset_id, status: job.status, jobId: job._id });
            if (['pipelines', 'convert'].includes(job.type || '') && NonRunningStates.includes(job.status)) {
                store.commit('Jobs/setCompleteJobsInfo', {
                    datasetId: job.dataset_id,
                    type: job.type,
                    title: job.title,
                    success: job.status === JobStatus.SUCCESS.value,
                });
            }
        }
    }
    runningJobs.forEach(updateJob);
    girderRest.$on('message:job_status', ({ data: job }) => {
        updateJob(job);
        eventBus.$emit('refresh-data-browser');
    });
}
export default jobModule;
