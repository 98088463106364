/**
 * MultiMap maintains a mapping from identifiers to groups of other identifiers
 */
export default class MultiMap {
    constructor() {
        this.mapping = new Map();
    }
    add(key, value) {
        const set = this.mapping.get(key);
        if (set) {
            set.add(value);
        }
        else {
            this.mapping.set(key, new Set([value]));
        }
    }
    remove(key, value) {
        const set = this.mapping.get(key);
        if (set) {
            set.delete(value);
        }
    }
    get(key) {
        return this.mapping.get(key);
    }
}
