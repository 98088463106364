import girderRest from 'platform/web-girder/plugins/girder';
function getBrandData() {
    return girderRest.get('dive_configuration/brand_data');
}
function putBrandData(brandData) {
    return girderRest.put('dive_configuration/brand_data', brandData);
}
function getPipelineList() {
    return girderRest.get('dive_configuration/pipelines');
}
function getTrainingConfigurations() {
    return girderRest.get('dive_configuration/training_configs');
}
function getAddons() {
    return girderRest.get('dive_configuration/addons');
}
function postAddons(urls, forceDownload) {
    return girderRest.post(`dive_configuration/upgrade_pipelines?force=${forceDownload}`, urls);
}
export { getBrandData, putBrandData, getPipelineList, getTrainingConfigurations, getAddons, postAddons, };
