import Track from './track';
import BaseAnnotationStore from './BaseAnnotationStore';
export default class TrackStore extends BaseAnnotationStore {
    add(frame, defaultType, afterId, overrideTrackId) {
        const track = new Track(overrideTrackId, {
            begin: frame,
            end: frame,
            confidencePairs: [[defaultType, 1]],
        });
        this.insert(track, { afterId });
        this.markChangesPending({ action: 'upsert', track, cameraName: this.cameraName });
        return track;
    }
}
