/*eslint class-methods-use-this: "off"*/
import Vue from 'vue';
export default class BaseLayer {
    constructor({ annotator, stateStyling, typeStyling, }) {
        this.annotator = annotator;
        this.stateStyling = stateStyling;
        this.typeStyling = typeStyling;
        this.formattedData = [];
        this.style = {};
        this.featureLayer = null;
        this.selectedIndex = [];
        this.bus = new Vue();
        this.initialize();
    }
    initialize() {
        this.style = this.createStyle();
        const style = {
            ...{
                stroke: true,
                uniformPolygon: true,
                strokeColor: this.stateStyling.standard && this.stateStyling.standard.color,
                strokeWidth: 1,
                fill: false,
            },
            ...this.style,
        };
        if (this.featureLayer && this.featureLayer.style) {
            this.featureLayer.style(style);
        }
    }
    changeData(frameData, comparisons = []) {
        this.formattedData = this.formatData(frameData, comparisons);
        this.redraw();
    }
    createStyle() {
        return {
            strokeColor: 'black',
            strokeWidth: 1.0,
            antialiasing: 0,
        };
    }
}
