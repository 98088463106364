import BaseLayer from '../BaseLayer';
export default class PointLayer extends BaseLayer {
    initialize() {
        const layer = this.annotator.geoViewerRef.value.createLayer('feature', {
            features: ['point'],
        });
        this.featureLayer = layer.createFeature('point');
        super.initialize();
    }
    // eslint-disable-next-line class-methods-use-this
    formatData(frameDataTracks) {
        const arr = []; //(this.checkHeadTail(frameData));
        frameDataTracks.forEach((frameData) => {
            var _a, _b;
            if (frameData.features && frameData.features.bounds) {
                if ((_b = (_a = frameData.features.geometry) === null || _a === void 0 ? void 0 : _a.features) === null || _b === void 0 ? void 0 : _b[0]) {
                    frameData.features.geometry.features.forEach((feature) => {
                        if (feature.geometry && feature.geometry.type === 'Point') {
                            const [x, y] = feature.geometry.coordinates;
                            let key = 'point';
                            if (feature.properties && feature.properties.key) {
                                key = feature.properties.key;
                            }
                            const annotation = {
                                trackId: frameData.track.id,
                                selected: frameData.selected,
                                editing: frameData.editing,
                                styleType: frameData.styleType,
                                feature: key,
                                x,
                                y,
                            };
                            arr.push(annotation);
                        }
                    });
                }
            }
        });
        return arr;
    }
    createStyle() {
        return {
            ...super.createStyle(),
            fill: (data) => data.feature === 'head',
            fillColor: (data) => {
                if (data.styleType) {
                    return this.typeStyling.value.color(data.styleType[0]);
                }
                return this.typeStyling.value.color('');
            },
            fillOpacity: (data) => {
                if (data.styleType) {
                    return this.typeStyling.value.opacity(data.styleType[0]);
                }
                return this.stateStyling.standard.opacity;
            },
            radius: (data) => {
                if (data.selected) {
                    return this.stateStyling.selected.strokeWidth * 2;
                }
                if (data.styleType) {
                    return this.typeStyling.value.strokeWidth(data.styleType[0]) * 2;
                }
                return this.stateStyling.standard.strokeWidth * 2;
            },
            strokeWidth: (data) => {
                if (data.selected) {
                    return this.stateStyling.selected.strokeWidth;
                }
                if (data.styleType) {
                    return this.typeStyling.value.strokeWidth(data.styleType[0]);
                }
                return this.stateStyling.standard.strokeWidth;
            },
            strokeColor: (data) => {
                if (data.selected) {
                    return this.stateStyling.selected.color;
                }
                if (data.styleType) {
                    return this.typeStyling.value.color(data.styleType[0]);
                }
                return this.typeStyling.value.color('');
            },
        };
    }
    redraw() {
        return this.featureLayer.data(this.formattedData).draw();
    }
    disable() {
        this.featureLayer
            .data([])
            .draw();
    }
}
