import { merge } from 'lodash';
import girderRest from '../plugins/girder';
const userModule = {
    namespaced: true,
    state: {
        user: null,
    },
    mutations: {
        setUserState(state, data) {
            state.user = merge(state.user, data);
        },
    },
    actions: {
        async loadUser({ commit }) {
            const data = await girderRest.fetchUser();
            commit('setUserState', data);
        },
    },
};
export default userModule;
