import girderRest from 'platform/web-girder/plugins/girder';
function postProcess(folderId, skipJobs = false, skipTranscoding = false, additive = false, additivePrepend = '', set = undefined) {
    return girderRest.post(`dive_rpc/postprocess/${folderId}`, null, {
        params: {
            skipJobs, skipTranscoding, additive, additivePrepend, set,
        },
    });
}
function runPipeline(itemId, pipeline) {
    return girderRest.post('dive_rpc/pipeline', null, {
        params: {
            folderId: itemId,
            pipeline,
        },
    });
}
function runTraining(folderIds, pipelineName, config, annotatedFramesOnly, labelText) {
    return girderRest.post('dive_rpc/train', { folderIds, labelText }, {
        params: {
            pipelineName, config, annotatedFramesOnly,
        },
    });
}
function convertLargeImage(folderId) {
    return girderRest.post(`dive_rpc/convert_large_image/${folderId}`, null, {});
}
export { convertLargeImage, postProcess, runPipeline, runTraining, };
