import girderRest from 'platform/web-girder/plugins/girder';
function deleteResources(resources) {
    const formData = new FormData();
    formData.set('resources', JSON.stringify({
        folder: resources
            .filter((resource) => resource._modelType === 'folder')
            .map((resource) => resource._id),
        item: resources
            .filter((resource) => resource._modelType === 'item')
            .map((resource) => resource._id),
    }));
    return girderRest.delete('resource', { data: formData });
}
function getItemsInFolder(folderId, limit) {
    return girderRest.get('item', {
        params: { folderId, limit },
    });
}
function getFolder(folderId) {
    return girderRest.get(`folder/${folderId}`);
}
function setUsePrivateQueue(userId, value = false) {
    return girderRest.put(`user/${userId}/use_private_queue`, null, {
        params: {
            privateQueueEnabled: value,
        },
    });
}
export { deleteResources, getItemsInFolder, getFolder, setUsePrivateQueue, };
