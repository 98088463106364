import girderRest from 'platform/web-girder/plugins/girder';
import { AnnotationsCurrentVersion } from 'platform/desktop/constants';
async function loadDetections(folderId, revision, set) {
    const params = { folderId };
    if (revision !== undefined) {
        params.revision = revision;
    }
    if (params !== undefined) {
        params.set = set;
    }
    return {
        tracks: (await girderRest.get('dive_annotation/track', { params })).data,
        groups: (await girderRest.get('dive_annotation/group', { params })).data,
        sets: (await girderRest.get('dive_annotation/sets', { params })).data,
        version: AnnotationsCurrentVersion,
    };
}
function loadRevisions(folderId, limit, offset, sort, set) {
    return girderRest.get('dive_annotation/revision', {
        params: {
            folderId, sortdir: -1, limit, offset, sort, set,
        },
    });
}
function saveDetections(folderId, args) {
    return girderRest.patch('dive_annotation', args, {
        params: { folderId },
    });
}
async function getLabels() {
    const response = await girderRest.get('dive_annotation/labels');
    return response;
}
export { getLabels, loadDetections, loadRevisions, saveDetections, };
